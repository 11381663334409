const routes = {
  inspireMe: "inspire_me",
  homepage: "",
  filter: "filter",
  individual: "individual",
  denim: "denim",
};

export const getIndividualRoute = (lookid = "") =>
  `${routes.individual}/${lookid.toLowerCase()}`;
export default routes;
