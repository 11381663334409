import React from "react";
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group";
import { useLanguage } from "../contexts/languageContext";
import Container from "../styles/Container";

const timeout = 500;
const getTransitionStyles = {
  entering: {
    position: `absolute`,
    opacity: 0,
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 0,
  },
};

const Transition = ({ children, location }) => {
  const { setEntered, scrollToTop } = useLanguage();
  return (
    <TransitionGroup>
      <ReactTransition
        key={location.pathname}
        timeout={{
          enter: timeout,
          exit: timeout,
        }}
        onExit={() => scrollToTop()}
        onEntered={() => {
          setEntered(true);
        }}
        onEnter={() => {
          setEntered(false);
        }}
        appear
        in
      >
        {(status) => (
          <Container
            style={{
              ...getTransitionStyles[status],
            }}
          >
            {children}
          </Container>
        )}
      </ReactTransition>
    </TransitionGroup>
  );
};
export default Transition;
