import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, navigate } from "gatsby";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Transition from "../components/Transition";
import LanguageSwitcher from "../components/LanguageSwitcher";
import Container from "../styles/Container";
import { LanguageProvider, useLanguage } from "../contexts/languageContext";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import routes from "../routes";
import { mobileBreakpoint } from "../styles/theme";

import { IconButton, RTL } from "../styles/common";
import Hamburger from "../components/Hamburger";
import Slider from "../components/Slider";
import FilterPanel from "../components/FilterPanel";
import CommonIconButton, { Icon } from "../components/CommonIconButton";
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import FakeLoader from "../components/FakeLoader";
import useGeoLocation from "react-ipgeolocation";

gsap.registerPlugin(ScrollToPlugin);

export const menuBarHeight = 60;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  z-index: 10;
  background: ${({ background }) => background};
`;
const HeaderContent = styled(Container)`
  display: flex;
  padding: 1em 0;
  @media (max-width: 1380px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (max-width: ${mobileBreakpoint}) {
    padding: 1em;
  }
`;
const WidgetWrapper = styled(Container)`
  display: flex;
  left: 0;
  right: 0;
  justify-content: end;
  align-items: center;
  grid-gap: 8px;
  position: fixed;
  padding-right: 8px;
  z-index: 10;
  top: 0;
  margin-top: ${menuBarHeight + 16}px;
  @media (max-width: ${mobileBreakpoint}) {
    padding-right: 16px;
    right: 0;
  }
`;
const DrawerMenu = styled.div`
  padding: 24px;
  height: 100%;
  background: ${({ background }) => background};
  overflow-y: auto;
  @media (max-width: ${mobileBreakpoint}) {
    height: calc(100vh - ${menuBarHeight}px);
    padding-bottom: 96px;
  }
`;
const LogoSide = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 16px;
  flex: 1;
  @media (max-width: ${mobileBreakpoint}) {
    justify-content: center;
  }
`;
const NavigationLink = styled.button`
  padding: 0;
  border: 0;
  font-family: inherit;
  background: transparent;
  font-size: 22px;
  line-height: 1.25em;
  font-weight: 400;
  color: ${({ color }) => color};
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: 16px;
  text-align: left;
  @media (max-width: ${mobileBreakpoint}) {
    font-size: 20px;
  }
`;

const AllLinks = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 0;
  margin: 16px 0;
  & ${NavigationLink} {
    margin: 8px 0;
  }
  @media (max-width: ${mobileBreakpoint}) {
  }
`;

const Widget = ({ pageContext }) => {
  const region = useMemo(() => pageContext.region, [pageContext]);
  const { copies, theme, lang, onChangeLanguage, isMobile, setDarkMode } =
    useLanguage();

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const toggleDrawer = useCallback(() => setDrawerOpen((prev) => !prev), []);

  const [showSlider, sliderDirection] = useMemo(
    () => [
      pageContext.pageType === "filter" || pageContext.pageType === "home",
      pageContext.pageType === "filter" ? "right" : "left",
    ],
    [pageContext]
  );

  const sliderAction = useCallback(() => {
    const destination =
      pageContext.pageType === "filter" ? routes.homepage : routes.filter;
    navigate(`/${pageContext.region}/${destination}`);
  }, [pageContext]);

  const showFilter = useMemo(
    () => pageContext.pageType === "filter",
    [pageContext]
  );
  const navigateToPage = useCallback((to) => {
    navigate(to);
    setDrawerOpen(false);
  }, []);
  const { closeIconSize, ...drawerProps } = useMemo(() => {
    return {
      isMobile,
      closeIconSize: isMobile ? "24px" : "48px",
      style: isMobile ? { top: menuBarHeight, boxShadow: "none" } : {},
      size: isMobile ? "90%" : 380,
      enableOverlay: isMobile ? true : true,
      overlayColor: isMobile ? "#fff" : "#000",
    };
  }, [isMobile]);

  useEffect(() => {
    if (pageContext.pageType === "filter") {
      setTimeout(() => {
        setDrawerOpen(true);
      }, 1000);
    }
  }, [pageContext.pageType]);

  const navLinkProps = useMemo(() => ({ color: theme.textColor }), [theme]);
  return (
    <>
      <WidgetWrapper>
        {showSlider && (
          <IconButton onClick={sliderAction}>
            <Slider direction={sliderDirection} />
          </IconButton>
        )}

        <CommonIconButton
          onClick={toggleDrawer}
          name="plus"
          width={20}
          height={20}
          alt="toggle_menu"
        />
      </WidgetWrapper>
      <Drawer
        open={drawerOpen}
        onClose={toggleDrawer}
        direction="right"
        {...drawerProps}
      >
        <DrawerMenu background={theme.backgroundColor}>
          <RTL float={isMobile ? "right" : "auto"}>
            <CommonIconButton
              onClick={() => setDrawerOpen(false)}
              name="close"
              width={closeIconSize}
              height={closeIconSize}
              alt="close"
            />
          </RTL>
          <NavigationLink
            onClick={() => navigateToPage(`/${region}`)}
            {...navLinkProps}
          >
            <Icon name="house" width={28} height={28} alt="home" />
          </NavigationLink>

          <AllLinks>
            <NavigationLink
              onClick={() => navigateToPage(`/${region}/denim`)}
              {...navLinkProps}
            >
              {copies.about_the_jeans}
            </NavigationLink>

            <NavigationLink
              onClick={() => navigateToPage(`/${region}/inspire_me`)}
              {...navLinkProps}
            >
              {copies.inspire_me}
            </NavigationLink>

            <NavigationLink
              {...navLinkProps}
              onClick={() =>
                navigateToPage(
                  pageContext.pageType === "filter"
                    ? `/${region}`
                    : `/${region}/filter`,
                  pageContext.pageType !== "filter"
                )
              }
            >
              {copies.filter}
              <div style={{ paddingBottom: 4 }}>
                {pageContext.pageType === "filter" ? (
                  <Slider disableHandle width={20} />
                ) : (
                  <Icon name="plus" width={16} height={16} alt="toggle_menu" />
                )}
              </div>
            </NavigationLink>

            {showFilter && <FilterPanel onClose={() => setDrawerOpen(false)} />}

            <NavigationLink
              onClick={() => setDarkMode((prev) => !prev)}
              {...navLinkProps}
            >
              {theme.darkMode ? copies.light_mode : copies.dark_mode}
            </NavigationLink>
            {pageContext.options?.length > 1 && (
              <LanguageSwitcher
                lang={lang}
                onChange={onChangeLanguage}
                options={pageContext.options}
              />
            )}
          </AllLinks>
        </DrawerMenu>
      </Drawer>
    </>
  );
};
const LinkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 24px;
  margin-left: 24px;
  @media (max-width: ${mobileBreakpoint}) {
    flex-direction: column;
  }
`;
const StoreLink = styled.a`
  font-size: 13px;
  line-height: 13px;
  text-transform: uppercase;
  padding: 0;
  border: 0;
  font-family: inherit;
  background: transparent;
  color: ${({ color }) => color};
`;
const HamburgerWrapper = styled.div`
  position: absolute;
  left: 16px;
  top: 16px;
`;

const MobileItems = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  margin: 24px 0;
`;

const MobileNav = ({ children, itemsCount }) => {
  const { region, theme } = useLanguage();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const toggleDrawer = useCallback(() => setDrawerOpen((prev) => !prev), []);

  return (
    <Drawer
      open
      onClose={toggleDrawer}
      direction="top"
      size={drawerOpen ? (itemsCount + 1) * 52 : menuBarHeight}
      zIndex={99}
      style={{
        background: theme.backgroundColor,
        overflow: "hidden",
        boxShadow: "none",
        borderBottom: `1px solid ${theme.mobileMenuBorder}`,
      }}
      enableOverlay={false}
    >
      <HamburgerWrapper>
        <IconButton
          onClick={() => setDrawerOpen((prev) => !prev)}
          color={theme.textColor}
        >
          <Hamburger width={24} color={theme.textColor} />
        </IconButton>
      </HamburgerWrapper>
      <HeaderContent>
        <LogoSide>
          <Link to={`/${region}`} onClick={() => setDrawerOpen(false)}>
            <img
              src="/logo.jpg"
              width={140}
              alt="calvin_klein"
              style={theme.imageFilter}
            />
          </Link>
        </LogoSide>
      </HeaderContent>
      <MobileItems>{children}</MobileItems>
    </Drawer>
  );
};

const utmSuffix = `?utm_source=lookbook&utm_medium=products&utm_campaign=FA22_Digital_Lookbook`;

const Header = ({ pageContext, ...props }) => {
  const { region, lang, isMobile, theme } = useLanguage();

  const allLinks = useMemo(() => {
    const links = props.data.allPrismicDataRegionEcommerce?.nodes;
    const localisedData = links.find((l) => l.lang === lang)?.data;
    return localisedData || [];
  }, [lang, props.data]);

  const items = useMemo(() => {
    if (!allLinks) return null;
    return (
      <LinkWrapper>
        {allLinks.links.map((link) => (
          <StoreLink
            key={link.label}
            href={link.link + utmSuffix}
            target="_blank"
            rel="noreferrer"
            color={theme.storeLinkColor}
          >
            {link.label}
          </StoreLink>
        ))}
      </LinkWrapper>
    );
  }, [allLinks, theme]);

  if (isMobile) {
    return (
      <>
        <MobileNav itemsCount={allLinks.links.length}>{items}</MobileNav>
        <div style={{ height: menuBarHeight }} />
      </>
    );
  }

  return (
    <StyledHeader background={theme.backgroundColor}>
      <HeaderContent>
        <LogoSide>
          <Link to={`/${region}`}>
            <img
              src="/logo.jpg"
              width={140}
              alt="home"
              style={theme.imageFilter}
            />
          </Link>
          {items}
        </LogoSide>
      </HeaderContent>
    </StyledHeader>
  );
};
const ScreenContent = styled.div`
  padding: 0;
  flex: 1;
  overflow-x: hidden;
  overflow-y: ${({ disableScrolling }) =>
    disableScrolling ? "hidden" : "auto"};
  position: relative;
  padding-bottom: 96px;
  scrollbar-gutter: stable;
  @media (max-width: ${mobileBreakpoint}) {
  }
`;

const ScreenLayout = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: ${({ background }) => background};
`;

const SeoTitle = ({ page, data }) => {
  const { copies, region } = useLanguage();

  const description =
    copies.meta_share_facebook_title || "Get inspired in #mycalvins";
  const title = useMemo(() => {
    if (page === "individual" && data?.prismicLook) {
      return `${copies.meta} ${data?.prismicLook?.data?.kol || ""}`;
    }
    return copies.meta;
  }, [copies.meta, page, data]);

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="description" content={description} />
      <meta
        property="og:url"
        content={`https://editions.calvinklein.com/${region}`}
      />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />

      <meta
        property="og:image"
        content="http://images.prismic.io/cncck/3fb61fe0-5706-47cb-9d08-27804cd4f6ae_facebook_cover.jpg?auto=compress,format"
      />
      <meta
        property="og:image:secure_url"
        content="https://images.prismic.io/cncck/3fb61fe0-5706-47cb-9d08-27804cd4f6ae_facebook_cover.jpg?auto=compress,format"
      />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="628" />
      <meta property="og:image:alt" content="#mycalvins" />

      <link
        rel="icon"
        type="image/png"
        href="https://editions.calvinklein.com/favicon.png"
        sizes="16x16"
      />
      <script>
        {`
     (function(d) {
      var config = {
        kitId: 'jin7omj',
        scriptTimeout: 3000,
        async: true
      },
      h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
    })(document);

    `}
      </script>
    </Helmet>
  );
};

const LayoutContent = ({
  children,
  containerRef,
  disableScrolling,
  ...props
}) => {
  const { theme, entered } = useLanguage();
  return (
    <ScreenLayout background={theme.backgroundColor}>
      <Header {...props} />
      <ScreenContent ref={containerRef} disableScrolling={disableScrolling}>
        <FakeLoader entered={entered} />

        <Transition location={props.location}>
          <Widget pageContext={props.pageContext} />
          {children}
        </Transition>
      </ScreenContent>
    </ScreenLayout>
  );
};
const Layout = (props) => {
  const location = useGeoLocation();
  const [pageLoaded, setPageLoaded] = useState(false);
  const ref = useRef();
  const [disableScrolling, setDisableScrolling] = useState(false);

  const scrollToTop = useCallback(() => {
    gsap.to(ref.current, {
      scrollTo: {
        y: 0,
        autoKill: true,
      },
    });
  }, []);

  useEffect(() => {
    setPageLoaded(true);
  }, []);

  if (!pageLoaded) return null;
  if (!props.pageContext?.region) {
    const { country } = location;
    const countryMapping = {
      HK: "/hk",
      JP: "/jp",
      MY: "/my",
      SG: "/sg",
      TW: "/tw",
      KR: "/kr",
    };

    if (country) {
      console.log("redirecting to", countryMapping[country] || "/hk");
      navigate(countryMapping[country] || "/hk");
      return null;
    }

    return null;
  }

  return (
    <LanguageProvider
      options={props.pageContext.options}
      region={props.pageContext.region}
      copies={props.data.allPrismicCopiesGeneral?.nodes}
      tags={props.data.allPrismicDataTag?.nodes}
      scrollToTop={scrollToTop}
      setDisableScrolling={setDisableScrolling}
      location={props.location}
    >
      {/* <SeoTitle page={props.pageContext.pageType} data={props.data} /> */}
      <LayoutContent
        {...props}
        containerRef={ref}
        disableScrolling={disableScrolling}
      />
    </LanguageProvider>
  );
};

export default Layout;
