import React from "react";
import styled from "styled-components";
import { useLanguage } from "../contexts/languageContext";

const Base = styled.button`
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-transform: none;
  cursor: pointer;
  -webkit-appearance: button;
  display: flex;
  align-items: center;
  outline: none;
  background-color: transparent;
  direction: ${({ direction }) => direction};
  border: 0;
  color: ${({ color }) => color || "rgb(120 113 108)"};
  &:disabled {
    cursor: initial;
  }
`;
export const Icon = ({
  width = 20,
  height = 20,
  name = "plus",
  alt = "icon",
}) => {
  const { theme } = useLanguage();
  return (
    <img
      src={`/icon/icon_${name}.png`}
      width={width}
      height={height}
      alt={alt}
      style={theme.imageFilter}
    />
  );
};
const CommonIconButton = ({ onClick, ...props }) => {
  return (
    <Base onClick={onClick}>
      <Icon {...props} />
    </Base>
  );
};
export default CommonIconButton;
