import gsap from "gsap";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLanguage } from "../contexts/languageContext";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ background }) => background};
  z-index: 9;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;
`;
const FakeLoader = ({ entered }) => {
  const [progress, setProgress] = useState(0);
  const { theme } = useLanguage();
  const [exiting, setExiting] = useState(false);

  useEffect(() => {
    let i = { value: 0 };
    gsap.to(i, {
      value: 1,
      duration: 2,
      ease: "steps(100)",
      onUpdate: (v) => {
        setProgress((prev) => (prev > v.value ? prev : v.value));
      },
      onUpdateParams: [i],
    });
  }, []);

  useEffect(() => {
    if (entered && !exiting) {
      setExiting(true);
      let i = { value: progress };
      gsap.to(i, {
        value: 1,
        ease: `steps(${(1 - progress <= 0 ? 1 : 1 - progress) * 50} )`,
        onUpdate: (v) => {
          setProgress((prev) => (prev > v.value ? prev : v.value));
        },
        onUpdateParams: [i],
      });
    }
  }, [entered, progress, exiting]);

  if (progress >= 1) {
    return null;
  }
  return (
    <Wrapper background={theme.backgroundColor}>
      {Math.floor(progress * 100)}%
    </Wrapper>
  );
};

export default FakeLoader;
