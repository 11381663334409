import styled from "styled-components";
import Container from "./Container";
import { mdBreakpoint, mobileBreakpoint } from "./theme";
import { GeneralButton } from "./common";
import { BREAKPOINTS } from "../contexts/languageContext";

export const FilterWrapper = styled(Container)`
  margin-top: 24px;
  padding: 64px 0 36px 0;
  color: ${({ color }) => color};
  @media (max-width: ${mobileBreakpoint}) {
    padding: 64px 0;
  }
`;

export const ActiveFilterGroup = styled.div`
  @media (max-width: ${BREAKPOINTS.desktop}px) {
    padding: 0 16px;
  }
  @media (max-width: ${mobileBreakpoint}) {
    padding: 0 8px;
  }
`;

export const FilterPageHeader = styled.h1`
  font-size: min(90px, 7vw);
  line-height: min(90px, 7vw);
  margin: 0;
  font-weight: 400;
  @media (max-width: ${mobileBreakpoint}) {
    text-align: center;
    font-size: max(40px, 6vw);
    line-height: max(40px, 6vw);
  }
`;

export const FilterPageSubtitle = styled.h2`
  font-size: min(36px, 3vw);
  line-height: min(36px, 3vw);
  font-weight: 100;
  @media (max-width: ${mobileBreakpoint}) {
    font-size: 16px;
    text-align: center;
  }
`;

export const FilterGridWrapper = styled.div`
  margin-top: 80px;
  @media (max-width: ${mobileBreakpoint}) {
    margin-top: 8px;
  }
`;

export const PhotoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(10, minmax(0, 1fr));
  grid-gap: 8px;
  & > * {
    display: flex;
  }
  @media (max-width: ${mdBreakpoint}) {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  @media (max-width: ${mobileBreakpoint}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const getGridArea = (location, total, actualSpan, listTotal) => {
  let rowNumber = Math.floor(location / total) + 1;
  let columnIndex = location % total;
  const centerStartPosition = (total - actualSpan) / 2;
  if (location === listTotal) {
    columnIndex -= 1;
    if (columnIndex === centerStartPosition) {
      if (location < total) {
        columnIndex = location + 1;
      } else {
        columnIndex = location % total;
      }
    } else if (columnIndex < centerStartPosition) {
      columnIndex = columnIndex + 2;
    } else {
      if (columnIndex + actualSpan > total) {
        rowNumber = rowNumber + 1;
        columnIndex = 1;
      } else {
        columnIndex += 2;
      }
    }
  }
  return `${!location ? 1 : rowNumber} / ${columnIndex}`;
};
export const PlayerGrid = styled.div`
  // row number / column index / row span / column span
  ${({ location, total, span, listTotal, height }) => {
    const gridArea = getGridArea(location, total, span, listTotal);
    return `grid-area: ${gridArea} / span 1 / span ${span};
    height: ${height};
    `;
  }}
`;

export const FilterGroupHeader = styled.div`
  font-size: 16px;
  margin: 8px 0;
  @media (max-width: ${mobileBreakpoint}) {
    margin: 4px 0 4px 0;
  }
`;

export const FilterPanelWrapper = styled.div`
  margin-bottom: 12px;
  color: ${({ color }) => color};
`;
export const FilterItem = styled(GeneralButton)`
  padding: 4px 0;
  font-size: 16px;
  border: 0;
  line-height: 10px;
  color: ${({ inactiveColor }) => inactiveColor};
  border-bottom: 1px solid transparent;
  ${({ selected, activeColor }) =>
    selected &&
    `
    color: ${activeColor};
    border-color:${activeColor};
  `}
  @media (max-width: ${mobileBreakpoint}) {
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 100;
  }
`;

export const FilterGroupWrapper = styled.div`
  margin: 16px 0;
  @media (max-width: ${mobileBreakpoint}) {
    margin: 8px 0;
  }
`;
export const FilterGroupItemsWrapper = styled.div`
  display: flex;
  grid-gap: 8px;
  flex-wrap: wrap;
  @media (max-width: ${mobileBreakpoint}) {
    column-gap: 16px;
    row-gap: 8px;
  }
`;

export const ActiveFilterPanelWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 8px 16px;
  min-height: 40px;
  @media (max-width: ${mobileBreakpoint}) {
    min-height: 0;
  }
`;
export const ActiveFilterButton = styled(GeneralButton)`
  display: flex;
  padding: 8px;
  font-size: 16px;
  color: ${({ color }) => color};
  border: 2px solid ${({ color }) => color};
  justify-content: center;
  align-items: center;
  grid-gap: 8px;

  & span {
    line-height: 0;
    padding-top: 3px;
  }
`;

export const OKButton = styled(GeneralButton)`
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  padding: 4px 16px 2px 16px;
  border: 0;
  margin-right: 16px;
`;
