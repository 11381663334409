import React, { useCallback } from "react";
import { useLanguage } from "../contexts/languageContext";
import upperCase from "lodash/upperCase";

import {
  FilterGroupHeader,
  FilterGroupWrapper,
  FilterItem,
  FilterPanelWrapper,
  FilterGroupItemsWrapper,
  OKButton,
} from "../styles/filter";
import { BorderedButton, GeneralButton } from "../styles/common";

const groupOrder = ["look", "occasion", "attitude", "jeans"];

const itemOrder = {
  look: ["masculine", "feminine", "rather_not_say"],
  occasion: [
    "9_to_5",
    "night_out",
    "gallery_hopping",
    "dinner_date",
    "glamping",
    "everyday_errands",
  ],
  attitude: ["carefree", "cheerful", "outgoing", "bold", "confident", "chill"],
  jeans: ["90s_straight", "italian_denim", "ultimate_stretch"],
};
const FilterPanel = ({ onClose }) => {
  const { copies, filters, theme, setFilters, resetFilter, isMobile } =
    useLanguage();

  const onSelectKey = useCallback(
    (key, category) => {
      setFilters((prev) => ({
        ...prev,
        [category]: prev[category] === key ? null : key,
      }));
    },
    [setFilters]
  );

  return (
    <FilterPanelWrapper color={theme.textColor}>
      {groupOrder.map((group) => (
        <FilterGroupWrapper key={group}>
          <FilterGroupHeader>
            {upperCase(copies[`tag_${group}`])}
          </FilterGroupHeader>
          <FilterGroupItemsWrapper>
            {itemOrder[group].map((tag, idx) => (
              <FilterItem
                key={idx}
                selected={filters[group] === tag}
                onClick={() => onSelectKey(tag, group)}
                inactiveColor={theme.inactiveFilterColor}
                activeColor={theme.activeFilterColor}
              >
                {copies[`tag_${tag}`]}
              </FilterItem>
            ))}
          </FilterGroupItemsWrapper>
        </FilterGroupWrapper>
      ))}
      <FilterGroupWrapper>
        {isMobile && (
          <OKButton
            background={theme.textColor}
            color={theme.backgroundColor}
            onClick={onClose}
          >
            OK
          </OKButton>
        )}

        <GeneralButton
          noBorder
          padding="0px"
          color={theme.textColor}
          onClick={resetFilter}
        >
          {copies.clear_all_filters}
        </GeneralButton>
      </FilterGroupWrapper>
    </FilterPanelWrapper>
  );
};

export default FilterPanel;
