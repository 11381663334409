import React from "react";
import styled from "styled-components";
import { useLanguage } from "../contexts/languageContext";

const Wrapper = styled.div`
  width: ${({ width }) => width || 30}px;
  display: flex;
  height: ${({ width }) => width || 30}px;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const Base = styled.div`
  height: 3px;
  background: ${({ color }) => color};
  flex: 1;
`;
const Handle = styled.div`
  height: 9px;
  width: 3px;
  background: ${({ color }) => color};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.1s ease-out;
  ${({ direction }) =>
    direction === "left"
      ? `
    left: 4px
  `
      : "right: 4px"}
`;

const Slider = ({ direction, disableHandle, width = 30 }) => {
  const { theme } = useLanguage();
  return (
    <Wrapper width={width}>
      <Base color={theme.textColor} />
      {!disableHandle && (
        <Handle direction={direction} color={theme.textColor} />
      )}
    </Wrapper>
  );
};
export default Slider;
