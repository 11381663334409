import styled from "styled-components";
import { mobileBreakpoint } from "./theme";

const Container = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
  // @media (max-width: 1280px) {
  //   width: 90vw;
  // }
  @media (max-width: ${mobileBreakpoint}) {
    width: 100vw;
  }
`;
export default Container;
