import * as React from "react";
import styled from "styled-components";
import { useLanguage } from "../contexts/languageContext";
import { GeneralButton } from "../styles/common";

const displayMap = {
  ZH: "中",
  EN: "EN",
};
const Option = styled(GeneralButton)`
  border: 0;
  padding: 0;
  line-height: 16px;
  color: ${({ colors }) => colors.inactive};
  ${({ selected, colors }) =>
    selected &&
    `
    color: ${colors.active};
    `}
`;
const Wrapper = styled.div`
  display: flex;
  grid-gap: 16px;
  margin-top: 16px;
`;
const LanguageSwitcher = ({ lang, options, onChange }) => {
  const { theme } = useLanguage();
  return (
    <Wrapper>
      {options.map((altLang, index) => (
        <Option
          selected={lang === altLang}
          key={`alt-lang-${index}`}
          onClick={() => onChange(altLang)}
          colors={{
            active: theme.activeLangColor,
            inactive: theme.inactiveLangColor,
          }}
        >
          {displayMap[altLang.slice(0, 2).toUpperCase()]}
        </Option>
      ))}
    </Wrapper>
  );
};

export default LanguageSwitcher;
