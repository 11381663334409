import React from "react";
import styled from "styled-components";
import { useLanguage } from "../contexts/languageContext";

export const GeneralButton = styled.button`
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-transform: none;
  cursor: pointer;
  -webkit-appearance: button;
  transition: 0.1s ease-in;
  font-size: 14px;
  background-color: transparent;
  ${({ upperCase }) =>
    upperCase &&
    `
    text-transform: uppercase;
  `}
  ${({ noBorder, borderColor }) =>
    noBorder
      ? `
      border: 0;
    `
      : `
  border: 1px solid ${borderColor || "rgb(120 113 108)"};
  `}
  padding: ${({ padding }) => padding || "12px 5em"};
  color: ${({ color }) => color || "rgb(120 113 108)"};
  &:disabled {
    cursor: initial;
  }
  &:hover {
    background: ${({ invertedBackground }) => invertedBackground};
    color: ${({ invertedTextColor }) => invertedTextColor};
  }
`;

export const IconButton = styled.button`
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-transform: none;
  cursor: pointer;
  -webkit-appearance: button;
  display: flex;
  align-items: center;
  outline: none;
  background-color: transparent;
  direction: ${({ direction }) => direction};
  border: 0;
  color: ${({ color }) => color || "rgb(120 113 108)"};
  &:disabled {
    cursor: initial;
  }
`;
export const RTL = styled.div`
  direction: rtl;
  ${({ float }) =>
    float &&
    `
  float: ${float}
  `}
`;

export const BorderedButton = (props) => {
  const { theme } = useLanguage();
  return (
    <GeneralButton
      {...props}
      upperCase
      borderColor={theme.buttonBorderColor}
      color={theme.buttonTextColor}
      invertedBackground={theme.textColor}
      invertedTextColor={theme.backgroundColor}
    />
  );
};
