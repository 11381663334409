import React, { useCallback, useEffect, useMemo, useState } from "react";
import useBreakpoint from "use-breakpoint";

const LanguageContext = React.createContext();

const defaultFilters = {
  look: null,
  occasion: null,
  attitude: null,
  jeans: null,
};
export const BREAKPOINTS = { mobile: 0, tablet: 769, desktop: 1280 };

export const LanguageProvider = ({
  children,
  options,
  copies: copiesProp,
  region,
  scrollToTop,
  tags,
  setDisableScrolling,
  location,
}) => {
  const [darkMode, setDarkMode] = useState(false);
  const [lang, setActiveLang] = useState(options?.[0]);
  const onChangeLanguage = useCallback((newLang) => {
    setActiveLang(newLang);
  }, []);
  const [entered, setEntered] = useState(false);

  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const [histories, setHistories] = useState({ prev: null, current: null });

  useEffect(() => {
    setHistories((prev) => ({
      prev: prev.current,
      current: location,
    }));
  }, [location]);

  const [filters, setFilters] = useState(defaultFilters);
  const [filteredLooks, setFilteredLooks] = useState([]);

  useEffect(() => {
    if (lang.includes("jp")) {
      const fileref = document.createElement("link");
      fileref.rel = "stylesheet";
      fileref.type = "text/css";
      fileref.href = "/japanese.css";
      document.getElementsByTagName("head")[0].appendChild(fileref);
    }
  }, [lang]);

  const theme = useMemo(() => {
    return {
      darkMode,
      textColor: darkMode ? "#fff" : "#000",
      backgroundColor: darkMode ? "#000" : "#fff",
      storeLinkColor: darkMode ? "#fff" : "#000",
      imageFilter: darkMode ? { filter: "invert(100%)" } : {},
      activeLangColor: darkMode ? "#fff" : "#000",
      inactiveLangColor: darkMode ? "#999" : "#999",
      stickyBackground: darkMode ? "#fff" : "#000",
      stickyTextColor: darkMode ? "#000" : "#fff",
      buttonBorderColor: darkMode ? "#fff" : "rgb(120 113 108)",
      buttonTextColor: darkMode ? "#fff" : "rgb(120 113 108)",
      activeFilterColor: darkMode ? "#fff" : "#000",
      inactiveFilterColor: darkMode ? "#999" : "#8b8b8b",
      buttonDisabledColor: darkMode ? "#666" : "#ccc",
      buttonDisabledBorderColor: darkMode ? "#999" : "#ececec",
      buttonDisabledBackgroundColor: darkMode ? "#999" : "#999",
      mobileMenuBorder: darkMode ? "#444" : "#ececec",
      separatorColor: darkMode ? "#999" : "rgb(229, 231, 235)",
      cardBoxShadow: darkMode ? "#888" : "rgb(0 0 0 / 60%)",
    };
  }, [darkMode]);

  const value = useMemo(
    () => ({
      lang,
      region,
      onChangeLanguage,
      copies: copiesProp?.find((p) => p.lang === lang)?.data,
      scrollToTop,
      filters,
      setFilters,
      resetFilter: () => setFilters(defaultFilters),
      tags: tags?.map((t) => ({ id: t.id, ...t.data })),
      setDisableScrolling,
      isMobile: breakpoint === "mobile",
      isTablet: breakpoint === "tablet",
      setDarkMode,
      theme,
      setEntered,
      entered,
      histories,
      filteredLooks,
      setFilteredLooks,
    }),
    [
      lang,
      onChangeLanguage,
      copiesProp,
      region,
      scrollToTop,
      filters,
      tags,
      setDisableScrolling,
      breakpoint,
      theme,
      entered,
      histories,
      filteredLooks,
    ]
  );

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => React.useContext(LanguageContext);
